import React from 'react';

const ReactTerms = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.1212 25.0601C22.4963 25.0528 20.9397 24.3814 19.7907 23.1924C18.6416 22.0033 17.9931 20.3928 17.9861 18.7113V13.6379C17.9861 11.9541 18.6323 10.339 19.7828 9.14837C20.9334 7.95775 22.4941 7.28906 24.1212 7.28906C25.7484 7.28906 27.3085 7.95775 28.459 9.14837C29.6096 10.339 30.2558 11.9541 30.2558 13.6379V18.6835C30.2558 20.3699 29.6108 21.9875 28.461 23.1825C27.3112 24.3776 25.7508 25.0528 24.1212 25.0601ZM24.1212 8.67525C22.8494 8.67525 21.6292 9.19813 20.7299 10.1288C19.8306 11.0595 19.3256 12.3218 19.3256 13.6379V18.6835C19.3256 19.9997 19.8306 21.262 20.7299 22.1926C21.6292 23.1233 22.8494 23.6462 24.1212 23.6462C25.3931 23.6462 26.6126 23.1233 27.5119 22.1926C28.4113 21.262 28.9162 19.9997 28.9162 18.6835V13.6102C28.9091 12.2988 28.4014 11.0437 27.5028 10.119C26.6042 9.1943 25.3885 8.67523 24.1212 8.67525Z"
      fill="#475467"
    />
    <path
      d="M30.872 41.0006H17.1287C15.9656 40.9933 14.8522 40.5119 14.0298 39.6608C13.2073 38.8097 12.7424 37.6575 12.7354 36.4538V31.214C12.7992 30.5974 12.9989 30.0042 13.3194 29.4802C13.6399 28.9563 14.0725 28.5157 14.5837 28.1922C15.6211 27.364 16.804 26.752 18.0667 26.3901L22.3528 25.1423C22.4373 25.1175 22.526 25.111 22.6131 25.123C22.7002 25.135 22.7837 25.1653 22.859 25.212C22.9343 25.2588 22.9995 25.3209 23.0507 25.3948C23.1018 25.4686 23.138 25.5527 23.1566 25.6415C23.1809 25.727 23.1881 25.8169 23.1782 25.9055C23.1683 25.994 23.1416 26.0797 23.0991 26.1572C23.0566 26.2348 22.9993 26.3026 22.931 26.3569C22.8626 26.4112 22.7848 26.4509 22.7014 26.4733L18.4414 27.7208C17.3394 28.0159 16.3088 28.5444 15.4144 29.2731C14.7982 29.7722 14.0749 30.4932 14.0749 31.214V36.4538C14.0749 37.2921 14.3962 38.0958 14.969 38.6885C15.5418 39.2812 16.3187 39.6144 17.1287 39.6144H30.8988C31.7042 39.6071 32.4746 39.2709 33.0416 38.679C33.6086 38.0871 33.9266 37.2873 33.9265 36.4538V31.214C33.9265 30.4932 33.2032 29.7722 32.6138 29.2731C31.7127 28.5554 30.6838 28.0281 29.5861 27.7208L25.4341 26.5285C25.2636 26.477 25.1192 26.3577 25.0338 26.1965C24.9484 26.0353 24.9285 25.8457 24.9782 25.6692C24.9998 25.5829 25.0382 25.5021 25.0907 25.4313C25.1432 25.3606 25.2087 25.3013 25.2837 25.2574C25.3586 25.2134 25.4414 25.1854 25.527 25.1751C25.6126 25.1649 25.6994 25.1727 25.7821 25.1978L29.9347 26.3901C31.2078 26.7459 32.4006 27.3584 33.4445 28.1922C33.9494 28.5203 34.3756 28.9625 34.6911 29.486C35.0067 30.0095 35.2033 30.6005 35.2661 31.214V36.4538C35.2661 37.0509 35.1526 37.6421 34.9318 38.1937C34.711 38.7453 34.3868 39.2467 33.9789 39.6689C33.5709 40.0911 33.0867 40.4259 32.5536 40.6544C32.0206 40.8829 31.449 41.0006 30.872 41.0006Z"
      fill="#475467"
    />
    <path
      d="M22.4862 26.4994C22.3085 26.4994 22.1382 26.4267 22.0126 26.2967C21.887 26.1667 21.8164 25.9901 21.8164 25.8063V24.365C21.8164 24.1812 21.887 24.0046 22.0126 23.8746C22.1382 23.7446 22.3085 23.6719 22.4862 23.6719C22.6638 23.6719 22.8341 23.7446 22.9597 23.8746C23.0853 24.0046 23.1559 24.1812 23.1559 24.365V25.8063C23.1559 25.9901 23.0853 26.1667 22.9597 26.2967C22.8341 26.4267 22.6638 26.4994 22.4862 26.4994Z"
      fill="#475467"
    />
    <path
      d="M25.5943 26.5549C25.4167 26.5549 25.2464 26.4818 25.1208 26.3519C24.9952 26.2219 24.9246 26.0456 24.9246 25.8618V24.365C24.9246 24.1812 24.9952 24.0046 25.1208 23.8746C25.2464 23.7446 25.4167 23.6719 25.5943 23.6719C25.772 23.6719 25.9423 23.7446 26.0679 23.8746C26.1935 24.0046 26.2641 24.1812 26.2641 24.365V25.8618C26.2641 26.0456 26.1935 26.2219 26.0679 26.3519C25.9423 26.4818 25.772 26.5549 25.5943 26.5549Z"
      fill="#475467"
    />
    <path
      d="M23.9866 32.5446C22.8108 32.3589 21.7497 31.71 21.0257 30.7334C20.3016 29.7568 19.9709 28.5282 20.1021 27.3048C20.1021 26.9721 20.1021 26.6672 20.1021 26.3345C20.1349 26.153 20.2336 25.9914 20.3781 25.8831C20.5227 25.7747 20.7016 25.728 20.8785 25.7524C21.0518 25.7799 21.2083 25.8764 21.3135 26.0215C21.4186 26.1666 21.4647 26.3486 21.4416 26.5285C21.4284 26.787 21.4284 27.0462 21.4416 27.3048C21.4416 29.4118 22.5935 31.1585 23.9866 31.1585C25.3797 31.1585 26.6657 29.4118 26.6657 27.3048C26.679 27.0462 26.679 26.787 26.6657 26.5285C26.6426 26.3486 26.6887 26.1666 26.7939 26.0215C26.8991 25.8764 27.0549 25.7799 27.2282 25.7524C27.402 25.7286 27.5778 25.7759 27.7181 25.8848C27.8583 25.9936 27.9519 26.1552 27.9784 26.3345C28.005 26.6574 28.005 26.9819 27.9784 27.3048C28.1146 28.5463 27.7716 29.7931 27.0241 30.7743C26.2766 31.7556 25.185 32.3919 23.9866 32.5446Z"
      fill="#475467"
    />
    <path
      d="M26.1307 14.4131C25.5646 14.413 25.0056 14.2803 24.4962 14.0249C23.9785 13.7576 23.5147 13.3909 23.1298 12.944C22.9049 12.6188 22.5857 12.3761 22.2187 12.2509C21.4418 12.0846 20.3704 12.722 19.138 14.0804C19.0128 14.2074 18.8444 14.2787 18.6691 14.2787C18.4937 14.2787 18.3253 14.2074 18.2001 14.0804C18.1353 14.0158 18.0842 13.9384 18.049 13.8527C18.0138 13.7669 17.9954 13.6748 17.9954 13.5816C17.9954 13.4884 18.0138 13.3962 18.049 13.3105C18.0842 13.2248 18.1353 13.1471 18.2001 13.0824C19.8075 11.2804 21.2277 10.5597 22.5405 10.8647C23.1606 11.0369 23.7134 11.4052 24.1214 11.9182C24.3778 12.2605 24.7077 12.5356 25.0855 12.722C25.782 13.0547 26.8805 13.2767 29.1845 11.8905C29.2619 11.8466 29.3467 11.8187 29.4344 11.8089C29.5221 11.7991 29.6107 11.8072 29.6953 11.8329C29.78 11.8587 29.8589 11.9018 29.9275 11.9592C29.9961 12.0166 30.0536 12.0874 30.0956 12.1676C30.1835 12.326 30.2075 12.5137 30.1623 12.6902C30.1172 12.8667 30.0063 13.0176 29.8543 13.1102C28.7425 13.8699 27.4599 14.3188 26.1307 14.4131Z"
      fill="#475467"
    />
    <path
      d="M37.0069 23.2566C35.5361 23.2566 34.1257 22.6519 33.0857 21.5756C32.0457 20.4994 31.4617 19.0396 31.4617 17.5176V13.1373C31.4088 12.352 31.5124 11.5638 31.7665 10.8218C32.0205 10.0798 32.4197 9.39977 32.9385 8.82411C33.4574 8.24846 34.0854 7.78968 34.783 7.47583C35.4807 7.16198 36.2331 7 36.9938 7C37.7545 7 38.5069 7.16198 39.2045 7.47583C39.9022 7.78968 40.5301 8.24846 41.049 8.82411C41.5679 9.39977 41.967 10.0798 42.2211 10.8218C42.4752 11.5638 42.5787 12.352 42.5259 13.1373V17.6286C42.4979 19.1265 41.9049 20.5538 40.8737 21.6054C39.8425 22.657 38.4546 23.2497 37.0069 23.2566ZM37.0069 8.67385C35.8913 8.67385 34.8216 9.13242 34.0328 9.9487C33.244 10.765 32.8012 11.872 32.8012 13.0263V17.5176C32.7549 18.1165 32.8286 18.719 33.0183 19.2869C33.208 19.8548 33.5094 20.3758 33.9033 20.8172C34.2972 21.2587 34.775 21.6112 35.3069 21.8521C35.8388 22.0931 36.4132 22.2173 36.9938 22.2173C37.5744 22.2173 38.1487 22.0931 38.6806 21.8521C39.2125 21.6112 39.6904 21.2587 40.0842 20.8172C40.4781 20.3758 40.7795 19.8548 40.9692 19.2869C41.1589 18.719 41.2327 18.1165 41.1864 17.5176V13.1373C41.2006 12.5588 41.1032 11.983 40.8999 11.4439C40.6966 10.9047 40.3916 10.4131 40.0025 9.99744C39.6133 9.58181 39.1478 9.25073 38.6335 9.02345C38.1192 8.79616 37.5661 8.67735 37.0069 8.67385Z"
      fill="#475467"
    />
    <path
      d="M28.5141 27.4137C28.4234 27.4125 28.334 27.392 28.2511 27.3538C28.1682 27.3157 28.0937 27.2606 28.032 27.1917C27.968 27.1295 27.9165 27.0545 27.8816 26.9711C27.8467 26.8876 27.8286 26.7977 27.8286 26.7068C27.8286 26.6158 27.8467 26.5259 27.8816 26.4425C27.9165 26.359 27.968 26.2837 28.032 26.2215C28.1355 26.1053 28.2522 26.0029 28.38 25.9165C29.3059 25.1554 30.3731 24.5988 31.5149 24.2809L35.319 23.1997C35.4034 23.1742 35.4919 23.1661 35.5793 23.176C35.6668 23.1859 35.7514 23.2134 35.8285 23.2572C35.9056 23.301 35.9736 23.3602 36.0287 23.4311C36.0837 23.5021 36.125 23.5837 36.1497 23.6711C36.1848 23.8506 36.1548 24.0369 36.066 24.1953C35.9771 24.3537 35.835 24.4729 35.667 24.5303L31.8629 25.6116C30.8912 25.8888 29.9812 26.3597 29.1838 26.9978L28.8889 27.2472C28.7819 27.3388 28.6519 27.3967 28.5141 27.4137Z"
      fill="#475467"
    />
    <path
      d="M42.9813 37.422H34.5693C34.472 37.4424 34.3713 37.4402 34.275 37.4155C34.1786 37.3909 34.0889 37.3445 34.012 37.2795C33.9352 37.2145 33.8734 37.1324 33.8309 37.0395C33.7883 36.9467 33.7661 36.8455 33.7661 36.7428C33.7661 36.64 33.7883 36.5384 33.8309 36.4456C33.8734 36.3528 33.9352 36.271 34.012 36.206C34.0889 36.141 34.1786 36.0946 34.275 36.07C34.3713 36.0453 34.472 36.0431 34.5693 36.0635H42.9813C43.6918 36.0635 44.3737 35.7712 44.8761 35.2513C45.3785 34.7314 45.6604 34.0264 45.6604 33.2911V28.6334C45.6604 28.1621 45.2587 27.58 44.5085 26.97C43.7094 26.3349 42.8 25.8643 41.8295 25.5838L38.1327 24.5303C37.9621 24.4789 37.8185 24.3595 37.733 24.1983C37.6476 24.0372 37.6271 23.8475 37.6768 23.6711C37.7265 23.4946 37.8422 23.3456 37.9979 23.2572C38.1537 23.1688 38.3369 23.1482 38.5075 23.1996L42.2049 24.2532C43.3446 24.5768 44.4101 25.1328 45.3392 25.8888C45.8044 26.1865 46.1963 26.5919 46.4845 27.0729C46.7727 27.554 46.9491 28.0976 46.9999 28.6611V33.3189C46.9964 33.8614 46.8898 34.3979 46.6859 34.8977C46.4821 35.3974 46.1853 35.8507 45.8121 36.2317C45.4389 36.6127 44.9968 36.9138 44.5112 37.1181C44.0255 37.3223 43.5055 37.4256 42.9813 37.422Z"
      fill="#475467"
    />
    <path
      d="M35.5338 24.5577C35.3609 24.5505 35.1971 24.4744 35.0773 24.3451C34.9574 24.2159 34.8901 24.0437 34.8902 23.8646V22.5616C34.8704 22.461 34.8729 22.3571 34.8967 22.2574C34.9206 22.1577 34.9654 22.0645 35.0282 21.985C35.091 21.9054 35.1701 21.8415 35.2597 21.7975C35.3494 21.7535 35.4476 21.7305 35.5469 21.7305C35.6461 21.7305 35.7443 21.7535 35.834 21.7975C35.9237 21.8415 36.0027 21.9054 36.0656 21.985C36.1284 22.0645 36.1732 22.1577 36.197 22.2574C36.2209 22.3571 36.2233 22.461 36.2036 22.5616V23.8646C36.2036 24.0484 36.133 24.2246 36.0073 24.3546C35.8817 24.4846 35.7114 24.5577 35.5338 24.5577Z"
      fill="#475467"
    />
    <path
      d="M38.2923 24.588C38.1147 24.588 37.9444 24.5149 37.8188 24.3849C37.6932 24.2549 37.6226 24.0787 37.6226 23.8949V22.5642C37.6226 22.3804 37.6932 22.2041 37.8188 22.0741C37.9444 21.9442 38.1147 21.8711 38.2923 21.8711C38.47 21.8711 38.6403 21.9442 38.7659 22.0741C38.8915 22.2041 38.9621 22.3804 38.9621 22.5642V23.8949C38.9621 24.0787 38.8915 24.2549 38.7659 24.3849C38.6403 24.5149 38.47 24.588 38.2923 24.588Z"
      fill="#475467"
    />
    <path
      d="M36.8729 29.7698C35.8062 29.6045 34.8424 29.0187 34.1834 28.1352C33.5243 27.2516 33.2207 26.1388 33.3363 25.0291C33.322 24.7428 33.322 24.4558 33.3363 24.1695C33.369 23.9859 33.4667 23.8217 33.6104 23.7089C33.7541 23.5961 33.9337 23.5427 34.1134 23.5597C34.2888 23.5936 34.4449 23.6957 34.5496 23.8453C34.6543 23.9949 34.6995 24.1807 34.6759 24.3638C34.6626 24.5853 34.6626 24.8075 34.6759 25.0291C34.6759 26.8589 35.6941 28.3836 36.8729 28.3836C38.0517 28.3836 39.0424 26.8589 39.0424 25.0291C39.0558 24.8075 39.0558 24.5853 39.0424 24.3638C39.0156 24.2684 39.009 24.1679 39.0228 24.0697C39.0366 23.9714 39.0709 23.8776 39.1229 23.7942C39.1749 23.7107 39.2436 23.6396 39.3243 23.5861C39.4051 23.5325 39.4962 23.4976 39.5912 23.4835C39.6862 23.4695 39.7824 23.4768 39.8744 23.5048C39.9665 23.5329 40.0518 23.5809 40.1243 23.646C40.1968 23.711 40.2551 23.7915 40.295 23.8818C40.3348 23.9722 40.3551 24.0702 40.3551 24.1695C40.5183 25.3691 40.2591 26.5895 39.6246 27.6076C38.99 28.6256 38.0224 29.3733 36.8991 29.7143L36.8729 29.7698Z"
      fill="#475467"
    />
    <path
      d="M38.7757 13.7763C38.2583 13.7786 37.7464 13.6651 37.2753 13.4436C36.8041 13.1996 36.3851 12.8603 36.043 12.4456C35.8522 12.181 35.5917 11.979 35.2928 11.8635C34.623 11.8635 33.6853 12.2515 32.6137 13.4714C32.4876 13.5758 32.3291 13.6295 32.1676 13.6227C32.0062 13.6158 31.8528 13.5489 31.7353 13.4341C31.6179 13.3194 31.5444 13.1646 31.5286 12.9983C31.5128 12.8319 31.5554 12.6651 31.649 12.5289C33.0957 10.8932 34.328 10.2279 35.5871 10.5328C36.1549 10.676 36.6569 11.019 37.0071 11.5031C37.2388 11.7794 37.5212 12.0055 37.8378 12.1684C38.454 12.4734 39.4187 12.6397 41.428 11.4476C41.5843 11.3557 41.7694 11.3319 41.9427 11.3813C42.116 11.4307 42.2633 11.5491 42.3522 11.7109C42.441 11.8726 42.464 12.0642 42.4163 12.2436C42.3685 12.4229 42.254 12.5754 42.0977 12.6673C41.1019 13.3284 39.9572 13.7106 38.7757 13.7763Z"
      fill="#475467"
    />
    <path
      d="M11.2077 23.2566C9.74154 23.2493 8.33779 22.6413 7.30356 21.5658C6.26933 20.4904 5.68865 19.0348 5.68866 17.5176V13.1373C5.63582 12.352 5.74002 11.5638 5.99411 10.8218C6.2482 10.0798 6.64666 9.39977 7.16555 8.82411C7.68444 8.24846 8.31237 7.78968 9.01002 7.47583C9.70767 7.16198 10.4601 7 11.2208 7C11.9815 7 12.7345 7.16198 13.4322 7.47583C14.1298 7.78968 14.7571 8.24846 15.276 8.82411C15.7949 9.39977 16.194 10.0798 16.4481 10.8218C16.7022 11.5638 16.8064 12.352 16.7535 13.1373V17.6286C16.7255 19.1313 16.1285 20.5625 15.0916 21.6149C14.0546 22.6673 12.6601 23.2569 11.2077 23.2566ZM11.2077 8.67385C10.0968 8.68117 9.03367 9.14303 8.25065 9.95852C7.46763 10.774 7.02817 11.8767 7.0282 13.0263V17.5176C6.98189 18.1165 7.0563 18.719 7.246 19.2869C7.4357 19.8548 7.73645 20.3758 8.1303 20.8172C8.52415 21.2587 9.00205 21.6112 9.53393 21.8521C10.0658 22.0931 10.6402 22.2173 11.2208 22.2173C11.8014 22.2173 12.3757 22.0931 12.9076 21.8521C13.4395 21.6112 13.9174 21.2587 14.3113 20.8172C14.7051 20.3758 15.0065 19.8548 15.1962 19.2869C15.3859 18.719 15.4603 18.1165 15.414 17.5176V13.1373C15.4283 12.5565 15.3299 11.9784 15.1249 11.4374C14.9199 10.8965 14.6123 10.4036 14.2203 9.98762C13.8283 9.57162 13.3596 9.24097 12.8422 9.01532C12.3248 8.78968 11.7692 8.67366 11.2077 8.67385Z"
      fill="#475467"
    />
    <path
      d="M13.3776 37.4217H4.96496C3.91338 37.4217 2.90521 36.9894 2.16163 36.2199C1.41804 35.4504 1 34.4068 1 33.3186V28.6608C1.05083 28.0973 1.22719 27.5537 1.51541 27.0726C1.80362 26.5916 2.19614 26.1862 2.66133 25.8885C3.5872 25.1273 4.65378 24.5707 5.79563 24.2528L9.5997 23.1716C9.68237 23.1464 9.76918 23.1387 9.85478 23.1489C9.94039 23.1591 10.0231 23.1872 10.0981 23.2311C10.1731 23.2751 10.2386 23.334 10.291 23.4048C10.3435 23.4755 10.3819 23.5567 10.4035 23.643C10.4533 23.8195 10.4334 24.0091 10.348 24.1703C10.2625 24.3314 10.1188 24.4508 9.94831 24.5023L6.1436 25.5835C5.1696 25.8545 4.25842 26.326 3.46453 26.9697C2.71439 27.5797 2.31272 28.1618 2.31272 28.6331V33.2908C2.31272 34.0261 2.59518 34.7311 3.0976 35.251C3.60002 35.7709 4.28125 36.0632 4.99178 36.0632H13.4044C13.5017 36.0428 13.6017 36.045 13.6981 36.0697C13.7944 36.0943 13.8842 36.1407 13.961 36.2057C14.0379 36.2707 14.1003 36.3525 14.1429 36.4453C14.1854 36.5381 14.207 36.6397 14.207 36.7424C14.207 36.8452 14.1854 36.9464 14.1429 37.0392C14.1003 37.1321 14.0379 37.2142 13.961 37.2792C13.8842 37.3442 13.7944 37.3906 13.6981 37.4152C13.6017 37.4399 13.5017 37.4421 13.4044 37.4217H13.3776Z"
      fill="#475467"
    />
    <path
      d="M19.3794 27.3856C19.2229 27.3796 19.0721 27.3211 18.9503 27.219L18.7096 26.9974C17.9105 26.3622 17.0011 25.8917 16.0306 25.6112L12.3331 24.5577C12.1626 24.5062 12.0189 24.3869 11.9335 24.2257C11.8481 24.0645 11.8282 23.8749 11.8779 23.6984C11.9276 23.5219 12.0433 23.3729 12.199 23.2845C12.3548 23.1961 12.538 23.1755 12.7086 23.227L16.3785 24.2805C17.5264 24.6042 18.6007 25.16 19.5397 25.9161L19.8346 26.1655C19.9574 26.2951 20.0263 26.4694 20.0263 26.6508C20.0263 26.8323 19.9574 27.0066 19.8346 27.1361C19.7784 27.2068 19.7094 27.2652 19.6312 27.3081C19.5531 27.3509 19.4675 27.3771 19.3794 27.3856Z"
      fill="#475467"
    />
    <path
      d="M9.76059 24.5602C9.58295 24.5602 9.41265 24.4871 9.28704 24.3572C9.16144 24.2272 9.09082 24.0509 9.09082 23.8671V22.5642C9.09082 22.3804 9.16144 22.2041 9.28704 22.0741C9.41265 21.9442 9.58295 21.8711 9.76059 21.8711C9.93822 21.8711 10.1085 21.9442 10.2341 22.0741C10.3597 22.2041 10.4304 22.3804 10.4304 22.5642V23.8671C10.4304 24.0509 10.3597 24.2272 10.2341 24.3572C10.1085 24.4871 9.93822 24.5602 9.76059 24.5602Z"
      fill="#475467"
    />
    <path
      d="M12.5208 24.588C12.3432 24.588 12.1729 24.5149 12.0473 24.3849C11.9217 24.2549 11.8511 24.0787 11.8511 23.8949V22.5642C11.8511 22.3804 11.9217 22.2041 12.0473 22.0741C12.1729 21.9442 12.3432 21.8711 12.5208 21.8711C12.6985 21.8711 12.8688 21.9442 12.9944 22.0741C13.12 22.2041 13.1906 22.3804 13.1906 22.5642V23.8949C13.1906 24.0787 13.12 24.2549 12.9944 24.3849C12.8688 24.5149 12.6985 24.588 12.5208 24.588Z"
      fill="#475467"
    />
    <path
      d="M11.0999 29.7715C10.0332 29.6062 9.06948 29.0205 8.41041 28.1369C7.75133 27.2534 7.44779 26.1406 7.56339 25.0308C7.54908 24.7446 7.54908 24.4575 7.56339 24.1712C7.58997 23.9899 7.68237 23.826 7.82175 23.7127C7.96112 23.5994 8.13705 23.5452 8.31361 23.5614C8.49398 23.5889 8.65668 23.6886 8.76688 23.8389C8.87707 23.9892 8.92591 24.1783 8.90292 24.3655C8.88965 24.5871 8.88965 24.8093 8.90292 25.0308C8.90292 26.8606 9.89436 28.3853 11.0999 28.3853C12.3055 28.3853 13.2701 26.8606 13.2701 25.0308C13.2835 24.8093 13.2835 24.5871 13.2701 24.3655C13.2549 24.2743 13.2572 24.1808 13.2773 24.0907C13.2975 24.0006 13.3348 23.9155 13.3872 23.8406C13.4396 23.7657 13.5063 23.7024 13.5828 23.6545C13.6592 23.6065 13.7442 23.5748 13.8326 23.5614C14.0092 23.5452 14.1851 23.5994 14.3245 23.7127C14.4639 23.826 14.5563 23.9899 14.5829 24.1712C14.5972 24.4575 14.5972 24.7446 14.5829 25.0308C14.6997 26.1323 14.4035 27.2382 13.7555 28.1203C13.1074 29.0025 12.1568 29.5934 11.0999 29.7715Z"
      fill="#475467"
    />
    <path
      d="M13.0028 13.7767C12.4854 13.779 11.9735 13.6655 11.5024 13.444C11.0312 13.2 10.6123 12.8607 10.2701 12.446C10.0793 12.1814 9.81879 11.9794 9.5199 11.8639C8.85014 11.6976 7.91246 12.2519 6.84084 13.4718C6.7807 13.5381 6.70825 13.591 6.62761 13.6271C6.54697 13.6632 6.46039 13.6819 6.37252 13.6819C6.28465 13.6819 6.19742 13.6632 6.11678 13.6271C6.03615 13.591 5.96369 13.5381 5.90356 13.4718C5.78084 13.3422 5.71191 13.1679 5.71191 12.9865C5.71191 12.8051 5.78084 12.6311 5.90356 12.5015C7.37704 10.8658 8.58257 10.2005 9.86852 10.5055C10.4311 10.6607 10.9297 11.0012 11.2885 11.4757C11.5202 11.7521 11.802 11.9782 12.1185 12.1411C12.7347 12.4461 13.6994 12.6124 15.7087 11.4202C15.7861 11.3763 15.8708 11.3485 15.9586 11.3387C16.0463 11.3289 16.1355 11.3373 16.2202 11.3631C16.3048 11.3888 16.3831 11.4315 16.4517 11.4889C16.5203 11.5464 16.5778 11.6172 16.6198 11.6974C16.7077 11.8557 16.7317 12.0435 16.6865 12.2199C16.6414 12.3964 16.5305 12.5473 16.3785 12.6399C15.3697 13.32 14.2052 13.7122 13.0028 13.7767Z"
      fill="#475467"
    />
  </svg>
);

export default ReactTerms;
